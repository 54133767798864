// 引入路由和storage工具函数
import { setPersonInfo, getPersonInfo } from './auth'
import {isMobile} from './util'
import {toLogin} from '@/utils/request'
// 忽略页面路径
const ignore = ['/user/login']

function checkTimeout(isReset = false) {
  if (!ignore.includes(window.location.pathname)) {
    const token = getPersonInfo()
    if (Object.keys(token).length !== 0) {
      isReset && setPersonInfo(token)
    } else {
      toLogin()
    }
  }
}

function astrict() {
  // 移动端不用30分钟自动退出
  if (isMobile()) return
  window.setInterval(checkTimeout, 30000)
  window.document.onmousedown = function() {
    checkTimeout(true)
  }
}
export default astrict

